<template>
  <div class="service-container">
    <!-- banner_service -->
    <div
      class="banner_service"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/banner_service.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="bg a-fdB">
          <ol>
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_logo.png"
            />
          </ol>
          <dd>欢迎使用挚达服务</dd>
          <!-- <dl>
                      <form>
                          <input type="text" placeholder="请输入您的问题">
                          <button type="button"></button>
                      </form>
                  </dl> -->
        </div>
      </div>
    </div>
    <!-- banner_service en -->
    <!-- service -->
    <div id="s1">
      <div class="margin width1480 hidden service_bg">
        <i class="md" id="d1"></i>
        <div class="tit">充电服务</div>
        <div class="service_zzfw">
          <div class="fl bg">
            <i
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zzfw1.jpg);
              "
            ></i>
            <div>
              <dd>安装服务</dd>
              <dl>产品到货后，您可以随时联系挚达专业人士上门安装。</dl>
            </div>
          </div>
          <div class="fr">
            <div class="bg t">
              <i
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zzfw2.jpg);
                "
              ></i>
              <div>
                <dd>售后服务</dd>
                <dl>
                  当产品出现故障，您可以联系挚达专业人员为您提供高品质的上门维修服务。
                </dl>
              </div>
            </div>
            <div class="bg f">
              <i
                style="
                  background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zzfw3.jpg);
                "
              ></i>
              <div>
                <dd>热线支持</dd>
                <dl>
                  <p>向所有客户提供7x24小时全年无休服务热线</p>
                  <p>电话：4006596580</p>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 挚达安装服务的4大优势 -->
    <div id="s2">
      <div class="service_ys">
        <div class="margin width1480 hidden">
          <i class="md" id="d2"></i>
          <div class="t">挚达安装服务的4大优势</div>
          <ul class="f">
            <li>
              <div>
                <ol>
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_ys1.png"
                  />
                </ol>
                <dd>严格的质量控制</dd>
                <dl>优选高品质耗材安装工艺专业规范</dl>
              </div>
            </li>
            <li>
              <i></i>
              <div>
                <ol>
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_ys2.png"
                  />
                </ol>
                <dd>40余项安装审核管理</dd>
                <dl>拥有强大的智能订单管理系统：安装详情全记录，安装进度可查询，售后质保可追溯</dl>
              </div>
            </li>
            <li>
              <i></i>
              <div>
                <ol>
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_ys3.png"
                  />
                </ol>
                <dd>高效的流程管控</dd>
                <dl>
                  安装详情全记录，安装进度可查询，售后质保可追溯
                </dl>
              </div>
            </li>
            <li>
              <i></i>
              <div>
                <ol>
                  <img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_ys4.png"
                  />
                </ol>
                <dd>安装网络覆盖360座城市</dd>
                <dl>挚达安装服务支持34个省，360个城市无忧上门</dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 安装准备 -->
    <div id="s3">
      <div class="service_azzb">
        <div class="margin width1480 hidden">
          <i class="md" id="d3"></i>
          <div class="t">安装准备</div>
          <ul class="f">
            <li
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_azzb1.png);
              "
            >
              <div>
                <ol>
                  01
                </ol>
                <dd>物业提前沟通</dd>
                <dl>
                  <p><b></b>咨询是否可以安装</p>
                  <p><b></b>容许安装的充电桩功率</p>
                  <p><b></b>需要提交的资质材料</p>
                </dl>
              </div>
            </li>
            <li
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_azzb2.png);
              "
            >
              <i></i>
              <div>
                <ol>
                  02
                </ol>
                <dd>申请电表</dd>
                <dl>
                  <p><b></b>向电力公司申请电表</p>
                  <p>
                    <b></b
                    >申请时需提交车位使用证明、身份证明、物业证明、车位及允许施工证明
                  </p>
                  <p><b></b>电力公司等第三方上门安装电表</p>
                </dl>
              </div>
            </li>
            <li
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_azzb3.png);
              "
            >
              <i></i>
              <div>
                <ol>
                  03
                </ol>
                <dd>咨询客服并下单</dd>
                <dl>
                  <p><b></b>收费透明</p>
                  <p><b></b>资质齐全（可协助提交物业所需资质证明）</p>
                  <p><b></b>售后有保障（全国覆盖，上门无忧）</p>
                </dl>
              </div>
            </li>
            <li
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_azzb4.png);
              "
            >
              <i></i>
              <div>
                <ol>
                  04
                </ol>
                <dd>派单员电话预约上门</dd>
                <dl>
                  <p><b></b>下单后，派单客服将在48小时内主动电话联络您</p>
                  <p><b></b>核实安装条件，如具备，则预约上门日期</p>
                  <p>
                    <b></b
                    >此过程中，客户可关注并进入“桩到家”公众号，点击底部菜单“充电服务”-“安装进度查询”，登录下单手机号后查询进展
                  </p>
                </dl>
              </div>
            </li>
            <li
              style="
                background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/service_azzb5.png);
              "
            >
              <i></i>
              <div>
                <ol>
                  05
                </ol>
                <dd>充电桩上门安装</dd>
                <dl>
                  <p><b></b>绘制施工图</p>
                  <p><b></b>告知可能的所有费用项目和金额</p>
                  <p><b></b>签字确认后开始施工</p>
                  <p><b></b>安装完成后通电测试</p>
                </dl>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 严控质量 做好监管 -->
    <div id="s4">
      <div class="service_zl">
        <div class="margin width1480 hidden">
          <i class="md" id="d4"></i>
          <div class="t">
            <dd>严控质量 做好监管</dd>
            <dl>无论是安装人员的培训选拔，还是安装服务的质量审核</dl>
          </div>
          <ul class="f">
            <li>
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zl1.png"
                />
              </ol>
              <dd>集中培训</dd>
            </li>
            <li>
              <i></i>
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zl2.png"
                />
              </ol>
              <dd>现场试训</dd>
            </li>
            <li>
              <i></i>
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zl3.png"
                />
              </ol>
              <dd>试单考核</dd>
            </li>
            <li>
              <i></i>
              <ol>
                <img
                  src="https://img.cdn.wxzhida.cn/shzd-web-site/images/service_zl4.png"
                />
              </ol>
              <dd>授权上岗</dd>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 了解您的产品 -->
    <div id="s5">
      <div class="margin width1480 service_bg service_cp">
        <i class="md" id="d5"></i>
        <div class="tit">了解您的产品</div>
        <div class="n">
          点击相关产品图片可下载产品手册，方便了解和使用您购买的产品
        </div>
        <div class="m">
          <span
            ><i>扫码查看说明书</i
            ><img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/ewm1.jpg"
              class="ewm"
          /></span>
        </div>
      </div>
      <div class="service_cp_f device">
        <div class="margin width1480 hidden">
          <a href="javascript:;" class="qh pre"
            ><i class="fa fa-angle-left"></i
          ></a>
          <div class="scroll">
            <ul>
              <li>
                <a @click="$U.jumpToPageName('kaituozhe', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp1.png"
                    />
                  </ol>
                  <dd>开拓者</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('tansuozhe', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp2.png"
                    />
                  </ol>
                  <dd>探索者</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('shouwangzhe', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp3.png"
                    />
                  </ol>
                  <dd>守望者</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('shouhuzhe', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp4.png"
                    />
                  </ol>
                  <dd>守护者</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('jingang', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp5.png"
                    />
                  </ol>
                  <dd>金刚</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('tiaozhanzhe', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_%E6%8C%91%E6%88%98%E8%80%85%E4%B8%BB%E9%A1%B5%E5%9B%BE_Ggb9IUcxPl.png"
                    />
                  </ol>
                  <dd>挑战者</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('shenxingxia', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp6.png"
                    />
                  </ol>
                  <dd>神行侠</dd>
                </a>
              </li>
              <li>
                <a @click="$U.jumpToPageName('xiaoheixia', '')">
                  <ol>
                    <img
                      src="https://img.cdn.wxzhida.cn/shzd-web-site/images/xl_cp7.png"
                    />
                  </ol>
                  <dd>小黑侠</dd>
                </a>
              </li>
            </ul>
          </div>
          <a href="javascript:;" class="qh next"
            ><i class="fa fa-angle-right"></i
          ></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
export default {
  name: 'service',
  components: {},
  setup() {
    const { proxy } = getCurrentInstance()
    const store = useStore()

    watch(
      () => store.state.curPageTarget,
      (newValue, oldValue) => {
        console.log('值发生了变更', newValue, oldValue)
        const jumpToTarget = newValue
        if (jumpToTarget) {
          document.querySelector('#' + jumpToTarget).scrollIntoView({
            behavior: 'smooth',
            // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
            block: 'start',
            // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
            inline: 'nearest',
            // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
          })
        }
      },
      { deep: true }
    )

    onMounted(() => {
      document.title = '服务支持'
      // 滚动到页面最上方
      window.scrollTo(0, 0)

      const jumpToTarget = proxy.$route.query.pagePostion
      if (jumpToTarget) {
        document.querySelector('#' + jumpToTarget).scrollIntoView({
          behavior: 'smooth',
          // 定义动画过渡效果， "auto"或 "smooth" 之一。默认为 "auto"
          block: 'start',
          // 定义垂直方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "start"
          inline: 'nearest',
          // 定义水平方向的对齐， "start", "center", "end", 或 "nearest"之一。默认为 "nearest"
        })
      }

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop() //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          //加循环
          if ($('#s' + i) && $('#s' + i).offset()) {
            if ($('#s' + i).offset().top <= wst) {
              //判断滚动条位置
              $('#s' + i).addClass('on') //给当前导航加c类
            } else {
              //$("#s"+i).removeClass('on');
            }
          }
        }
      })

      var $number = Math.ceil($('.scroll ul li').length / 1) //获取滚动几屏个数
      var margin = 0 //设置图片间距
      var $w = $('.scroll li').width() + margin // 一屏图片的宽度
      var $width = $w * $number * 1 //设置ul宽度
      var pre = $('.device .pre')
      var next = $('.device .next')
      if ($number == 1) {
        pre.hide()
        next.hide()
      }
      $('.scroll ul').width($width)
      var num = 0
      var $numimg = $number * 1 //产品个数
      //下一组
      next.click(function () {
        num++
        var leftdis = -1 * $w * num
        var long_img = $numimg * $w
        var sum = leftdis + -1 * $w //预留产品个数 3
        var zero = long_img + sum
        //if($width+leftdis == 0){
        if (zero == 0) {
          num--
          var leftdis = -1 * $w * num
          $('.scroll ul').stop().animate({ left: leftdis })
        } else {
          $('.scroll ul').stop().animate({ left: leftdis })
        }
      })
      //上一组
      pre.click(function () {
        num--
        console.log(num)
        if (num < 0) {
          num = 0
        } else {
          var rightdis = -1 * $w * num
          $('.scroll ul').stop().animate({ left: rightdis })
        }
      })
    })
    return {}
  },
}
</script>

<style scoped></style>
